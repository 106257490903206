import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import UserWithrawals from "./Withdrawals";
import UserDeposits from "./Deposits";
export default function Statements() {
  const [activeTab, setActiveTab] = React.useState("deposits");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="referral tabs"
      >
        <Tab value="deposits" label="Deposits" />
        <Tab value="withdrawals" label="Withdrawals" />
      </Tabs>
      <Box
        sx={{
          p: 2,
          bgcolor: "background.default",
          borderTop: 2,
          borderColor: "divider",
        }}
      >
        {activeTab === "deposits" && <UserDeposits />}
        {activeTab === "withdrawals" && <UserWithrawals />}
      </Box>
    </Box>
  );
}
